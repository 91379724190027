import { defineMessages } from 'react-intl';
 
const Locale = defineMessages({
    partners_list_title: {
        id: 'productinsights.partners_list_title',
        defaultMessage: 'Partners List',
    },
    product_list_title: {
        id: 'productinsights.product_list_title',
        defaultMessage: 'Product Catalog',
    },
    cw_home: {
        id: 'productinsights.cw_home',
        defaultMessage: 'CW Home',
    },
    marketplace: {
        id: 'productinsights.marketplace',
        defaultMessage: 'Marketplace',
    },
    all: {
        id: 'productinsights.all',
        defaultMessage: 'All',
    },
    logout: {
        id: 'productinsights.logout',
        defaultMessage: 'Logout',
    },
    no_records: {
        id: 'productinsights.no_records',
        defaultMessage: 'No records found',
    },
    no_partners: {
        id: 'productinsights.no_partners',
        defaultMessage: 'No Partners Found',
    },
    details: {
        id: 'productinsights.details',
        defaultMessage: 'Details',
    },
    transactions: {
        id: 'productinsights.transactions',
        defaultMessage: 'Transactions',
    },
    products: {
        id: 'productinsights.products',
        defaultMessage: 'Products',
    },
    product_instances: {
        id: 'productinsights.product_instances',
        defaultMessage: 'Product Instances',
    },
    instances: {
        id: 'productinsights.instances',
        defaultMessage: 'Instances',
    },
    clients: {
        id: 'productinstances.clients',
        defaultMessage: 'Clients',
    },
    credit_cards: {
        id: 'productinsights.credit_cards',
        defaultMessage: 'Credit Cards',
    },
    support: {
        id: 'productinsights.support',
        defaultMessage: 'Support',
    },
    asio_product_entitlements: {
        id: 'productinsights.asio_product_entitlements',
        defaultMessage: 'Asio Product Entitlements',
    },
    users: {
        id: 'productinsights.users',
        defaultMessage: 'Users',
    },
    reassign_user: {
        id: 'productinsights.reassign_user',
        defaultMessage: 'Reassign User',
    },
    save: {
        id: 'productinsights.save',
        defaultMessage: 'Save',
    },
    delete_tenant: {
        id: 'productinsights.delete_tenant',
        defaultMessage: 'Delete Tenant',
    },
    delete: {
        id: 'productinsights.delete',
        defaultMessage: 'Delete',
    },
    reassign_product_instance: {
        id: 'productinsights.reassign_productinstance',
        defaultMessage: 'Reassign Product Instance',
    },
    required: {
        id: 'productinsights.required',
        defaultMessage: 'Required',
    },
    float: {
        id: 'productinsights.float',
        defaultMessage: 'Number field',
    },
    access_denied_subtitle: {
        id: 'productinsights.access_denied_subtitle',
        defaultMessage: "You don't have access to this page.",
    },
    access_denied_title: {
        id: 'productinsights.access_denied_title',
        defaultMessage: 'Access denied',
    },
    scheduled_transactions: {
        id: 'productinsights.scheduled_transactions',
        defaultMessage: 'Scheduled Transactions',
    },
    error_signing_in: {
        id: 'productinsights.error_signing_in',
        defaultMessage: 'Error signing in',
    },
    try_again: {
        id: 'productinsights.try_again',
        defaultMessage: 'Try Again',
    },
    cancel: {
        id: 'productinsights.cancel',
        defaultMessage: 'Cancel',
    },
    confirm_refund: {
        id: 'productinsights.confirm_refund',
        defaultMessage: 'Confirm Refund',
    },
    cant_find_page: {
        id: 'productinsights.cant_find_page',
        defaultMessage: "'Hmm... We can't seem to locate this page'",
    },
    check_url: {
        id: 'productinsights.check_url',
        defaultMessage: 'Please check the URL',
    },
    take_me_home: {
        id: 'productinsights.take_me_home',
        defaultMessage: 'Take me home',
    },
    partner_id_label: {
        id: 'productinsights.partner_id_label',
        defaultMessage: 'PARTNER ID',
    },
    company_id_label: {
        id: 'productinsights.company_id_label',
        defaultMessage: 'MNG COMPANY ID',
    },
    company_recid_label: {
        id: 'productinsights.company_recid_label',
        defaultMessage: 'MNG REC ID',
    },
    partner_users_count: {
        id: 'productinsights.users_count',
        defaultMessage: 'USERS',
    },
    partner_clients_count: {
        id: 'productinsights.clients_count',
        defaultMessage: 'CLIENTS',
    },
    partner_instances_count: {
        id: 'productinsights.instances_count',
        defaultMessage: 'INSTANCES',
    },
    partner_name: {
        id: 'productinsights.partner_name',
        defaultMessage: 'NAME',
    },
    create: {
        id: 'productinsights.create',
        defaultMessage: 'Create',
    },
    map_features: {
        id: 'productinsights.map_features',
        defaultMessage: 'Map Features',
    },
    add_new_item: {
        id: 'productinsights.add_new_item',
        defaultMessage: 'Add New Item',
    },
    something_went_wrong: {
        id: 'productinsights.page_error',
        defaultMessage: 'Uh-oh. Something went wrong.',
    },
    contact_app_services: {
        id: 'productinsights.contact_app_services',
        defaultMessage: 'Please contact App Services.',
    },
    progress_step: {
        id: 'productinsights.progress_step',
        defaultMessage: 'Step {currentStep} of {totalSteps}',
    },
    yes: {
        id: 'productinsights.yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'productinsights.no',
        defaultMessage: 'No',
    },
    confirm: {
        id: 'productinsights.confirm',
        defaultMessage: 'Confirm',
    },
    provision: {
        id: 'productinsights.provision',
        defaultMessage: 'Provision',
    },
    provision_product: {
        id: 'productinsights.provision_product',
        defaultMessage: 'Provision Product & Packages',
    },
    product_provisioned: {
        id: 'productinsights.product_provisioned',
        defaultMessage: 'Product Provisioned.',
    },
    provision_asio: {
        id: 'productinsights.provision_asio',
        defaultMessage: 'Provision Asio',
    },
    provision_asio_permission_error: {
        id: 'productinsights.provision_asio_permission_error',
        defaultMessage: 'Missing required permissions',
    },
    provision_good_bms: {
        id: 'productinsights.provision_good_bms',
        defaultMessage: 'Provision Asio Essentials',
    },
    show_x: {
        id: 'productinsights.show_x',
        defaultMessage: 'Show {x}',
    },
    provision_asio_contactDropDown_enterEmail: {
        id: 'productinsights.contactDropDown_EnterEmail',
        defaultMessage: 'Enter Email'
      },
      provision_good_bms_industryDropDown_enterIndustry: {
        id: 'productinsights.industryDropDown_EnterIndustry',
        defaultMessage: 'Enter Industry'
      },
      provision_good_bms_regionDropDown_enterRegion: {
        id: 'productinsights.regionDropDown_EnterRegion',
        defaultMessage: 'Enter Region'
      },
    enter_email: {
        id: 'enter_email',
        defaultMessage: 'Enter Email'
      },
    enter_product: {
        id: 'enter_product',
        defaultMessage: 'Enter Product'
      },
    enter_package: {
        id: 'enter_package',
        defaultMessage: 'Enter Package'
      },
    provision_asio_flyout_content: {
        id: 'productinsights.provisionAsio_FlyoutContent',
        defaultMessage: 'Member Code can only consist of letters (a-z, A-Z), numbers (0-9) and hyphens ( - ). It cannot be empty or longer than 30 characters.'
    },
   
    client_detail_partner_id_label: {
        id: 'productinsights.clientDetail_partnerId_label',
        defaultMessage: 'Partner Id'
    },
    client_detail_product_label: {
        id: 'productinsights.clientDetail_product_label',
        defaultMessage: 'Product'
    },
    client_detail_client_id_label: {
        id: 'productinsights.clientDetail_clientId_label',
        defaultMessage: 'Client Id'
    },
    client_detail_redirect_uris_label: {
        id: 'productinsights.clientDetail_redirectUris_label',
        defaultMessage: 'Redirect URIs'
    },
    client_detail_usersWithAssignedAccess_label: {
        id: 'productinsights.clientDetail_usersWithAssignedAccess_label',
        defaultMessage: 'Users with Assigned Access'
    },
    client_detail_numberOfAssignedUsers_label: {
        id: 'productinsights.clientDetail_numberOfAssignedUsers_label',
        defaultMessage: 'Number of Assigned Users'
    },
    client_detail_remove: {
        id: 'productinsights.clientDetail_remove',
        defaultMessage: 'Remove'
    },
    client_detail_migrate: {
        id: 'productinsights.clientDetail_migrate',
        defaultMessage: 'Migrate Client'
    },
    client_detail_addRedirectUri: {
        id: 'productinsights.clientDetail_addRedirectUri',
        defaultMessage: 'Add Redirect URI'
    },
    client_detail_assignAdditionalUser: {
        id: 'productinsights.clientDetail_assignAdditionalUser',
        defaultMessage: 'Assign Additional User'
    },
    client_detail_assignUserDialog_userId: {
        id: 'productinsights.client_detail_assignUserDialog_userId',
        defaultMessage: 'User Id'
    },
    client_detail_assignUserDialog_email: {
        id: 'productinsights.client_detail_assignUserDialog_email',
        defaultMessage: 'Email'
    },
    client_detail_assignUserDialog_firstName: {
        id: 'productinsights.client_detail_assignUserDialog_firstName',
        defaultMessage: 'First Name'
    },
    client_detail_assignUserDialog_lastName: {
        id: 'productinsights.client_detail_assignUserDialog_lastName',
        defaultMessage: 'Last Name'
    },
    client_detail_assignUserDialog_noUserSelected: {
        id: 'productinsights.client_detail_assignUserDialog_noUserSelected',
        defaultMessage: 'No user selected !'
    },
    client_detail_assignUserDialog_assignUser: {
        id: 'productinsights.client_detail_assignUserDialog_assignUser',
        defaultMessage: 'Assign User'
    },
    client_detail_assignUserDialog_userAlreadyAssigned: {
        id: 'productinsights.client_detail_assignUserDialog_userAlreadyAssigned',
        defaultMessage: 'User already assigned !'
    },
    client_detail_assignUserDialog_userAssignedSuccessfully: {
        id: 'productinsights.client_detail_assignUserDialog_userAssignedSuccessfully',
        defaultMessage: 'User assigned successfully !'
    },
    client_detail_assignUserDialog_errorAssigningUser: {
        id: 'productinsights.client_detail_assignUserDialog_errorAssigningUser',
        defaultMessage: 'Error assigning user !'
    },
    client_detail_userRemovedSuccessfully: {
        id: 'productinsights.client_detail_userRemovedSuccessfully',
        defaultMessage: 'User removed successfully.'
    },
    client_detail_userRemovedFailed: {
        id: 'productinsights.client_detail_userRemovedFailed',
        defaultMessage: 'Failed to remove user.'
    },
    provisionactions: {
        id: 'productinsights.provisionactions',
        defaultMessage: 'Provision Actions',
    },
    products_and_packages: {
        id: 'productinsights.products_and_packages',
        defaultMessage: 'Products & Packages',
    },
    packages_instances: {
        id: 'productinsights.packages_instances',
        defaultMessage: 'Package Instances',
    },
    packages: {
        id: 'productinsights.packages',
        defaultMessage: 'Packages',
    },
    package_provisioned: {
        id: 'productinsights.package_provisioned',
        defaultMessage: 'Package Provisioned.',
    },
    entitlements: {
        id: 'productinsights.entitlements',
        defaultMessage: 'Entitlements',
    },
    entitlement_management: {
        id: 'productinsights.entitlement_management',
        defaultMessage: 'Entitlement Management',
    },
    status: {
        id: 'productinsights.status',
        defaultMessage: 'Status',
    },
    retry: {
        id: 'productinsights.retry',
        defaultMessage: 'Retry',
    },
    only_marketplace_products: {
        id: 'productinsights.only_marketplace_products',
        defaultMessage: 'Only Marketplace Products',
    },
    add_new_package: {
        id: 'productinsights.add_new_package',
        defaultMessage: 'Add New Package',
    },
    package_sku: {
        id: 'productinsights.package_sku',
        defaultMessage: 'SKU',
    },
    package_product_count: {
        id: 'productinsights.package_product_count',
        defaultMessage: 'Product Count',
    },
    description: {
        id: 'productinsights.description',
        defaultMessage: 'Description',
    },
    name: {
        id: 'productinsights.name',
        defaultMessage: 'Name',
    },
    package_licensed_partner: {
        id: 'productinsights.package_licensed_partner',
        defaultMessage: 'Licensed Partners',
    },
    package_included_products: {
        id: 'productinsights.package_included_products',
        defaultMessage: 'Included Products',
    },
    package_product_entitlement_search: {
        id: 'productinsights.package_product_entitlement_search',
        defaultMessage: 'Type at least three characters to search here for and add a product after selecting product type',
    },
    package_new_group: {
        id: 'productinsights.package_new_group',
        defaultMessage: 'To add new group please search here for products/entitlement',
    },
    package_package: {
        id: 'productinsights.package_package',
        defaultMessage: 'Package'
    },
    package_provisioning_groups: {
        id: 'productinsights.package_provisioning_groups',
        defaultMessage: 'Provisioning Groups',
    },
    package_id: {
        id: 'productinsights.package_id',
        defaultMessage: 'Package Id',
    },
    package_provisioned_count: {
        id: 'productinsights.package_provisioned_count',
        defaultMessage: 'Package Provisioned count',
    },
    provisioned_partners: {
        id: 'productinsights.provisioned_partners',
        defaultMessage: 'Provisioned Partners',
    },
    signup_json: {
        id: 'productinsights.signup_json',
        defaultMessage: 'Signup Json',
    },
    signup_form: {
        id: 'productinsights.signup_form',
        defaultMessage: 'Signup Form',
    },
    signup_template_saved: {
        id: 'productinsights.signup_template_saved',
        defaultMessage: 'Signup Template Saved',
    },
    signup_template_not_saved: {
        id: 'productinsights.signup_template_not_saved',
        defaultMessage: 'Signup Template Not Saved',
    },
    missing_permissions_msg: {
        id: 'productinsights.missing_permissions_msg',
        defaultMessage: 'Missing Required Permissions',
    },
    linkAccount: {
        id:'productinsights.link_account',
        defaultMessage: 'Link Account',
    },
    ownedBy: {
        id: 'productinsights.relationship_type_owned_by',
        defaultMessage: 'Owned By'
    },
    noChildAccount: {
        id: 'productinsights.no_child_account_linked',
        defaultMessage: 'No Child Account Linked'
    },
    noParentAccount: {
        id: 'productinsights.no_parent_account_linked',
        defaultMessage: 'No Parent Account Linked'
    },
    unlink_from_partner_warning:{
        id: 'productinsights.unlink_from_partner_warning',
        defaultMessage: 'Are you sure you want to de-link the mapping? Doing this would disrupt the access controls on the Franchise account'
    },
    view_all_product_entitlement_management: {
        id: 'productinsights.view_all_product_entitlement_management',
        defaultMessage: 'View All Products'
    },
    download_all_product_entitlement_management: {
        id: 'productinsights.download_all_product_entitlement_management',
        defaultMessage: 'Download All Products'
    },
    view_all_feature_entitlement_management: {
        id: 'productinsights.view_all_feature_entitlement_management',
        defaultMessage: 'View All Features'
    },
    download_all_feature_entitlement_management: {
        id: 'productinsights.download_all_feature_entitlement_management',
        defaultMessage: 'Download All Features'
    },
    create_product_entitlement_name_hint_msg: {
        id: 'create_product_entitlement_name_hint_msg',
        defaultMessage:
            'The product name should have specific format. The format will be "ConnectWise_actual product name_any alphabetical characters". Example : ConnectWise_RMM_ASIO Essentials',
    },
    create_product_entitlement_sku_hint_msg: {
        id: 'create_product_entitlement_sku_hint_msg',
        defaultMessage:
            'User can mention the SKU of the product created. Example : CW_VULNERABILITYMGNT',
    },
    create_feature_entitlement_name_hint_msg: {
        id: 'create_feature_entitlement_name_hint_msg',
        defaultMessage:
            'The feature name should have specific format. The format will be DOMAIN-NAME_Feature-Name. Example: ALERTING_Feature-Name',
    },
    search_all_product_entitlement_management: {
        id: 'productinsights.search_all_product_entitlement_management',
        defaultMessage: 'Search Product'
    },
    search_all_feature_entitlement_management: {
        id: 'productinsights.search_all_feature_entitlement_management',
        defaultMessage: 'Search Feature'
    },
    update: {
        id: 'productinsights.update',
        defaultMessage: 'Update',
    },
    product_entitlement_delete_warning: {
        id: 'productinsights.product_entitlement_delete_warning',
        defaultMessage: 'Are you sure you no longer want "{productName} product?"',
    },
    product_entitlement_delete_success: {
        id: 'productinsights.product_entitlement_delete_success',
        defaultMessage: '{productName} product deleted successfully',
    },
    product_entitlement_delete_failed: {
        id: 'productinsights.product_entitlement_delete_failed',
        defaultMessage: 'Failed to delete {productName} product',
    },
    delete_selected_entitlements: {
        id: 'productinsights.delete_selected_entitlements',
        defaultMessage: 'Delete Selected Entitlements',
    },
});
 
export default Locale;